import request from '@/utils/request'

export function login(username, password) {
  return request({
    url: '/Admin/Login',
    method: 'post',
    params: {
      username,
      password
    }
  })
}
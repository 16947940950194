<template>
  <router-view />
</template>

<script>
  export default {
    mounted: function() {
      //此方法刷新页面时也会执行
      window.addEventListener('beforeunload', () => {
        localStorage.clear()
      });
    }
  }
</script>

<style>
  html,
  body {
    margin: 0;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background-color: #f9f9f9;
  }
</style>

const nav = [{
  label: '用户管理',
  icon: 'el-icon-user-solid',
  children: [{
      path: '/user',
      label: '后台用户',
      component: () => import('../views/UserMgr')
    },
    {
      path: '/wx',
      label: '微信用户',
      component: () => import('../views/UserMgr/wx')
    },
    {
      path: '/salesman',
      label: '销售员',
      component: () => import('../views/UserMgr/salesman')
    },
    {
      path: '/log',
      label: '操作记录',
      component: () => import('../views/UserMgr/log')
    }
  ]
}, {
  label: '交易记录',
  icon: 'el-icon-money',
  children: [{
      path: '/pay',
      label: '微信支付记录',
      component: () => import('../views/Trade/pay')
    },
    {
      path: '/trade',
      label: '用户交易记录',
      component: () => import('../views/Trade/trade')
    }
  ]
}, {
  label: '日上券',
  icon: 'el-icon-postcard',
  children: [{
    path: '/vipCode',
    label: '前台购券',
    component: () => import('../views/VipCode')
  }, {
    path: '/vipCode/manual',
    label: '后台领券',
    component: () => import('../views/VipCode/manual')
  }]
}, {
  label: '贵宾室',
  icon: 'el-icon-postcard',
  children: [{
    path: '/lounge',
    label: '订单',
    component: () => import('../views/Lounge')
  }]
}, {
  label: '分销商',
  icon: 'el-icon-s-custom',
  children: [{
      path: '/distributor',
      label: '分销商',
      component: () => import('../views/Distributor/distributor')
    },
    {
      path: '/package',
      label: '库存',
      component: () => import('../views/Distributor/package')
    },
    {
      path: '/statistics',
      label: '分销统计',
      component: () => import('../views/Distributor/statistics')
    },
    {
      path: '/bridgeCode',
      label: '分销券',
      component: () => import('../views/VipCode/bridge')
    }
  ]
}]

export default nav

import {
  createStore
} from 'vuex'
import md5 from 'js-md5'
import {
  login
} from '@/api/admin'

export default createStore({
  state: {
    username: null,
    token: null,
    user_type: null,
  },
  mutations: {
    SET_USER: (state, user) => {
      state.username = user.username
      localStorage.setItem(process.env.VUE_APP_STORE_USERNAME_KEY, state.username)
      sessionStorage.setItem(process.env.VUE_APP_STORE_USERNAME_KEY, state.username)

      state.token = user.token
      localStorage.setItem(process.env.VUE_APP_STORE_TOKEN_KEY, state.token)
      sessionStorage.setItem(process.env.VUE_APP_STORE_TOKEN_KEY, state.token)

      state.user_type = user.user_type
      localStorage.setItem(process.env.VUE_APP_STORE_USER_TYPE_KEY, state.user_type)
      sessionStorage.setItem(process.env.VUE_APP_STORE_USER_TYPE_KEY, state.user_type)
    },
    RESTORE: (state) => {
      if (!state.username) {
        state.username = localStorage.getItem(process.env.VUE_APP_STORE_USERNAME_KEY) || sessionStorage.getItem(
          process.env.VUE_APP_STORE_USERNAME_KEY)
        if (state.username) {
          localStorage.setItem(process.env.VUE_APP_STORE_USERNAME_KEY, state.username)
          sessionStorage.setItem(process.env.VUE_APP_STORE_USERNAME_KEY, state.username)
        }
      }

      if (!state.token) {
        state.token = localStorage.getItem(process.env.VUE_APP_STORE_TOKEN_KEY) || sessionStorage.getItem(process
          .env.VUE_APP_STORE_TOKEN_KEY)
        if (state.token) {
          localStorage.setItem(process.env.VUE_APP_STORE_TOKEN_KEY, state.token)
          sessionStorage.setItem(process.env.VUE_APP_STORE_TOKEN_KEY, state.token)
        }
      }

      if (!state.user_type) {
        state.user_type = localStorage.getItem(process.env.VUE_APP_STORE_USER_TYPE_KEY) || sessionStorage.getItem(
          process
          .env.VUE_APP_STORE_USER_TYPE_KEY)
        if (state.user_type) {
          console.log(process.env.VUE_APP_STORE_USER_TYPE_KEY)
          localStorage.setItem(process.env.VUE_APP_STORE_USER_TYPE_KEY, state.user_type)
          sessionStorage.setItem(process.env.VUE_APP_STORE_USER_TYPE_KEY, state.user_type)
        }
      }
    },
    CLEAR: (state) => {
      state.username = null
      state.token = null
      state.user_type = null
      localStorage.clear()
      sessionStorage.clear()
    }
  },
  actions: {
    Login({
      commit
    }, userInfo) {
      const username = userInfo.username.trim()
      const password = md5(userInfo.password)
      return new Promise((resolve, reject) => {
        login(username, password).then(res => {
          if (res.success) {
            commit('SET_USER', {
              username,
              token: res.token,
              user_type: res.type + ''
            })
            resolve(res)
          } else {
            reject(res.message)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    Logout({
      commit
    }) {
      return new Promise((resolve /*, reject*/ ) => {
        commit('CLEAR')
        resolve()
      })
    }
  },
  getters: {
    username: state => state.username,
    token: state => state.token,
    user_type: state => state.user_type
  }
})

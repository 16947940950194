import {
  createRouter,
  createWebHistory
} from 'vue-router'
import routes from './config'
import store from '../store'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('RESTORE')
  let token = store.getters.token

  if (to.path.startsWith('/sunrise')) {
    if (to.path.indexOf('/my') != -1)
      document.title = '我的折扣券';
    else
      document.title = '日上93折折扣券';
    next()
  } else if (to.path === '/login') {
    if (token) {
      next('/')
    } else {
      next()
    }
  } else {
    if (token) {
      next()
    } else {
      next('/login')
    }
  }
})

export default router

import nav from './nav'

let routesData = []
// 多维数据变一纬数组 ==》递归
nav.forEach(_forEach)

function _forEach(data) {
  if (data.children) {
    data.children.forEach(_forEach)
  } else {
    routesData.push(data)
  }
}

const routes = [{
    path: "/",
    component: () => import("../views/layout/AppLayout"),
    children: [{
        path: '',
        redirect: routesData[0].path,
      }, {
        path: '/login',
        component: () => import("../views/Login"),
      },
      ...routesData
    ]
  },
  {
    path: "/sunrise",
    component: () => import("../views/layout/SunriseLayout"),
    children: [{
        path: '',
        component: () => import("../views/Sunrise/index"),
      },
      {
        path: '/sunrise/detail',
        component: () => import("../views/Sunrise/detail"),
      },
      {
        path: '/sunrise/my',
        component: () => import("../views/Sunrise/my"),
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/404')
  }
]

export default routes
